import { createSlice } from "@reduxjs/toolkit";
import { signInUser } from "../Components/Utility/Auth";

const user = signInUser();

// const {id, warehouse} = user;
// console.log("user from  posSlice:", user,id,warehouse);

const intialState = {
  invoiceId: "0171000000",
  source: "POS",
  warehouse: user?.warehouse,
  company: user?.company,
  products: [],
  returnProducts: [],
  returnCal: {
    totalItem: 0,
    total: 0,
    vat: 0,
    grossTotal: 0,
    grossTotalRound: 0,
    point: 0,
  },
  returnInvoice: null,
  paidAmount: {
    cash: 0,
    mfs: {
      name: "bkash",
      amount: 0,
    },
    card: {
      name: "visa",
      amount: 0,
    },
    point: 0,
  },
  changeAmount: 0,
  totalReceived: 0,
  grossTotal: 0,
  grossTotalRound: 0,
  totalItem: 0,
  total: 0,
  vat: 0,
  point: {
    old: 0,
    new: 0,
  },
  todayPoint: 0,
  due: false,
  billType: "paid",
  discount: 0,
  billerId: user?.id,
  group:null,
  customerId: "62e301c1ee6c8940f6ac1515",
  customerPhone: "0171000000",
  customerName: "Walkway Customer",
  updateUser: user?.id,
  promo_discount: 0,
  status: "complete",
};

export const posSlice = createSlice({
  name: "posSales",
  initialState: intialState,

  reducers: {
    selectInvoiceId: (state, action) => {
      return {
        ...state,
        invoiceId: action.payload,
      };
    },
    selcetUpdateUser: (state, action) => {
      return { ...state, updateUser: action.payload };
    },
    selcetBiller: (state, action) => {
      return {
        ...state,
        billerId: action.payload,
      };
    },
    selectWarehouse: (state, action) => {
      return {
        ...state,
        warehouse: action.payload,
      };
    },

    selcetCustomer: (state, action) => {
      // console.log("Add Customer", action.payload);
      // const newPoint = state.point.new + action.payload.point;
      return {
        ...state,
        customerId: action.payload.customerId,
        point: {
          old: action.payload.point,
          new: state.todayPoint + action.payload.point,
        },
        customerPhone: action.payload.phone,
        customerName: action.payload.name,
        group: action.payload.group ?  action.payload.group : null,

      };
    },
    selectGroup:(state, action)=>{
      return {
       ...state,
        group: action.payload.group,
      };
    },
    selcetProductsCart: (state, action) => {
      return {
        ...state,
        products: action.payload,
      };
    },
    saleFinalize: (state, action) => {
      // console.log(state.discount);
      // console.log(
      //   state.point.old,
      //   action.payload.newPoint,
      //   state.paidAmount.point
      // );
      const pointToday = Math.floor(action.payload.grossTotalRound / 100);
      const newPoint =
        Number(state.point.old) + pointToday - state.paidAmount.point;

      // console.log("action.payload", action.payload);
      return {
        ...state,
        totalItem: action.payload.totalItem,
        total: action.payload.total,
        vat: action.payload.vatAmount - action.payload.reVat,
        grossTotal:
          action.payload.grossTotal -
          action.payload.promo_discount -
          action.payload.discount -
          action.payload.reGrossTotalRound,
        grossTotalRound:
          action.payload.grossTotalRound -
          action.payload.promo_discount -
          action.payload.discount -
          action.payload.reGrossTotalRound,
        // discount: action.payload.discount,
        promo_discount: action.payload.promo_discount,

        changeAmount:
          state.totalReceived +
          action.payload.reTotal -
          action.payload.grossTotalRound,
        point: {
          ...state.point,
          new: newPoint - action.payload.rePoint,
        },
        todayPoint: pointToday,
        returnCal: {
          ...state.returnCal,
          totalItem: parseFloat(action.payload.reTotalItem),
          total: action.payload.reTotal,
          grossTotalRound: action.payload.reGrossTotalRound,
          grossTotal: action.payload.reGrossTotal,
          point: action.payload.rePoint,
          vat: action.payload.reVat,
        },
      };
    },
    salesPromoPrice: (state, action) => {
      return {
        ...state,
        promo_discount: action.payload,
      };
    },
    salesAmountRecived: (state, action) => {
      return {
        ...state,
        totalReceived: action.payload.totalRecivedAmount,
        changeAmount: action.payload.changeAmount - state.promo_discount,
      };
    },

    saleCash: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          cash: action.payload,
        },
      };
    },
    saleCardName: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          card: { ...state.paidAmount.card, name: action.payload },
        },
      };
    },
    saleCardAmount: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          card: { ...state.paidAmount.card, amount: action.payload },
        },
      };
    },
    saleMfsName: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          mfs: { ...state.paidAmount.mfs, name: action.payload },
        },
      };
    },
    saleMfsAmount: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          mfs: { ...state.paidAmount.mfs, amount: action.payload },
        },
      };
    },
    salePointAmount: (state, action) => {
      return {
        ...state,
        paidAmount: {
          ...state.paidAmount,
          point: action.payload,
        },
      };
    },
    saleNewPoint: (state, action) => {
      return {
        ...state,
        point: {
          ...state.point,
          new: action.payload,
        },
      };
    },
    saleDiscount: (state, action) => {
      return {
        ...state,
        discount: action.payload,
      };
    },
    saleReturnProducts: (state, action) => {
      return {
        ...state,
        returnProducts: action.payload,
      };
    },
    saleReturnInfo: (state, action) => {
      return {
        ...state,
        returnInvoice: action.payload.returnInvoice,
        customerId: action.payload.customerId,
        customerPhone: action.payload.customerPhone,
        customerName: action.payload.customerName,
        point: {
          ...state.point,
          old: action.payload.customerPoint,
        },
      };
    },

    saleReturnQty: (state, action) => {
      let seletedItem = state.returnProducts.filter(
        (item) => item.article_code === action.payload.id
      );
      let restItem = state.returnProducts.filter(
        (item) => item.article_code !== action.payload.id
      );
      seletedItem = {
        ...seletedItem,
        qty: action.payload.qty,
      };
      restItem = { ...restItem, seletedItem };
      const returnItems = restItem.sort((a, b) => a.order - b.order);
      return {
        ...state,
        returnProducts: returnItems,
      };
    },
    saleReset: () => intialState,
    setDue: (state, action) => {
      return {
        ...state,
        due: action.payload,
        billType: action.payload === true ? "due" : "paid",
      };
    },
  },
});

export const {
  selcetBiller,
  selcetCustomer,
  selcetProductsCart,
  saleFinalize,
  salesAmountRecived,
  saleCash,
  saleCardName,
  saleCardAmount,
  saleMfsName,
  saleMfsAmount,
  salePointAmount,
  saleDiscount,
  saleNewPoint,
  saleReset,
  selectInvoiceId,
  saleReturnProducts,
  saleReturnInfo,
  saleReturnQty,
  salesPromoPrice,
  setDue,
  selcetUpdateUser,
  selectGroup,
  selectWarehouse,
} = posSlice.actions;

export const posReducer = posSlice.reducer;
