import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "../models/user.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

export const UserApi = createApi({
  reducerPath: "UserApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    Users: builder.query<User[], any>({
      query: ({warehouse}) => `/user/${warehouse}`,
      providesTags: ["User"],
    }),
    User: builder.query<User, string>({
      query: (_id) => `/user/${_id}`,
      providesTags: ["User"],
    }),
    UserDw: builder.query<User, string>({
      query: () => `/user/Dw`,
      providesTags: ["User"],
    }),
    BillerDw: builder.query<User, string>({
      query: (warehouse) => `/user/biller/${warehouse}`,
      providesTags: ["User"],
    }),
    ManagerDw: builder.query<User, string>({
      query: () => `/user/manager`,
      providesTags: ["User"],
    }),
    addUser: builder.mutation<{}, User>({
      query: (User) => ({
        url: "/user/register",
        method: "POST",
        body: User,
      }),
      invalidatesTags: ["User"],
    }),
    loginUser: builder.mutation<{}, User>({
      query: (User) => ({
        url: "/user/login",
        method: "POST",
        body: User,
      }),
      invalidatesTags: ["User"],
    }),
    validUser: builder.mutation<{}, User>({
      query: (User) => ({
        url: "/user/valid",
        method: "POST",
        body: User,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation<void, User>({
      query: ({ _id, ...rest }) => ({
        url: `/user/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useUsersQuery,
  useUserQuery,
  useAddUserMutation,
  useLoginUserMutation,
  useUpdateUserMutation,
  useValidUserMutation,
  useDeleteUserMutation,
  useUserDwQuery,
  useBillerDwQuery,
  useManagerDwQuery,
} = UserApi;

export default UserApi;
