import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Customer } from "../models/customer.model";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:5001/api/";

// console.log(BASE_URL);

export const CustomerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ["Customer"],
  endpoints: (builder) => ({
    Customers: builder.query<Customer[], any>({
      query: (warehouse) => `/customer/${warehouse}`,
      providesTags: ["Customer"],
    }),
    CustomersExport: builder.query<Customer[], any>({
      query: ({warehouse}) => `/customer/export/${warehouse}`,
      providesTags: ["Customer"],
    }),
    Customer: builder.query<Customer, string>({
      query: (id) => `/customer/select/${id}`,
      providesTags: ["Customer"],
    }),
    GetCustomerById: builder.query<Customer[], any>({
      query: ({ id }) =>
        `/customer/getCustomerById/${id}`,
      providesTags: ["Customer"],
    }),
    customerCount: builder.query<Customer[], void>({
      query: () => "/customer/count",

      providesTags: ["Customer"],
    }),
    CustomerDW: builder.query<Customer[], void>({
      query: () => `/customer/dw/`,
      providesTags: ["Customer"],
    }),

    customerContact: builder.query<Customer, any>({
      query: ({ page, size, q }) => `/customer/contact/${page}/${size}?q=${q}`,
      // query: ({page, size, q}) => `/Customer`,
      providesTags: ["Customer"],
    }),
    customerPagenation: builder.query<Customer, any>({
      query: ({ page, size,warehouse, q }) => `/customer/all/${page}/${size}/${warehouse}?q=${q}`,
      // query: ({page, size, q}) => `/Customer`,
      providesTags: ["Customer"],
    }),
    addCustomer: builder.mutation<{}, Customer>({
      query: (Customer) => ({
        url: "/customer",
        method: "POST",
        body: Customer,
      }),
      invalidatesTags: ["Customer"],
    }),
    updateCustomer: builder.mutation<void, Customer>({
      query: ({ _id, ...rest }) => ({
        url: `/customer/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Customer"],
    }),
    updatePointCustomer: builder.mutation<void, Customer>({
      query: ({ _id, ...rest }) => ({
        url: `/customer/point/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Customer"],
    }),
    deleteCustomer: builder.mutation<void, string>({
      query: (id) => ({
        url: `/customer/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Customer"],
    }),
  }),
});

export const {
  useCustomersQuery,
  useCustomersExportQuery,
  useCustomerQuery,
  useGetCustomerByIdQuery,
  // useCustomerPointQuery,
  useCustomerDWQuery,
  useAddCustomerMutation,
  useCustomerCountQuery,
  useCustomerPagenationQuery,
  useCustomerContactQuery,
  useUpdateCustomerMutation,
  useUpdatePointCustomerMutation,
  useDeleteCustomerMutation,
} = CustomerApi;

export default CustomerApi;
