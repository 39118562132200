import React, { useEffect, useState } from "react";
import { Toast, Card, Table } from "react-bootstrap";
import Header from "../../Common/Header/Header";
import SideBar from "../../Common/SideBar/SideBar";
import * as Icons from "heroicons-react";
import LineChart from "./Chart/LineChart";
import PieChart from "./Chart/PieChart";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { startOfToday, endOfToday, format, formatDistance } from "date-fns";
import {
  useDashboardSaleQuery,
  useSaleFootfallQuery,
  useSalesPointSpentQuery,
  useSalesWeeklyQuery,
  useSaleTotalQuery,
} from "../../../services/saleApi";
import {
  useTodayGrnsQuery,
  useWeeklyGrnsQuery,
} from "../../../services/grnApi";
import { useTodayDamagesQuery } from "../../../services/damageApi";
import subDays from "date-fns/subDays";
import { signInUser } from "../../Utility/Auth";
import WareHouseDW from "../../Common/CustomSelect/WareHouseDW";

const AdminDashboard = () => {
  const [startDate, setStartDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "MM-dd-yyyy"));
  const auth = signInUser();
  const [warehouse, setWarehouse] = useState("allWh");
  //
  const { data, error, isLoading, isFetching, isSuccess, refetch } =
    useDashboardSaleQuery({
      startDate: startDate,
      endDate: endDate,
      warehouse,
    });

  console.log(data);

  useEffect(() => {
    // console.log(total);
  }, [data?.isSuccess]);

  const { data: weeklySale, isSuccess: isSuccessSale } = useSalesWeeklyQuery();
  // const { data: weeklyPurchase, isSuccess: isSuccessPurchase } = useWeeklyPurchasesQuery();
  const { data: weeklyGRN, isSuccess: isSuccessGRN } = useWeeklyGrnsQuery();
  const { data: todayTotalGRN, isSuccess: isSuccessTodayGRN } =
    useTodayGrnsQuery();

  const { data: todayTotalDamage, isSuccess: isSuccessTodayDamage } =
    useTodayDamagesQuery();
  // const [weekDates, setWeekDates] = useState([])
  const [weekSales, setWeekSales] = useState(0);
  // const [weekPurchase, setWeekPurchase] = useState([])
  const [weekGRN, setWeekGRN] = useState([]);
  const [lossProfit, setLossProfit] = useState(0);
  const [todaySale, setTodaySale] = useState(0);
  const [todayGrn, setTodayGrn] = useState(0);
  const [todayDamage, setTodayDamage] = useState(0);
  useEffect(() => {
    // console.log(total);
    if (data?.length > 0) {
      setTodaySale(data?.mrpTotal);
    }
    // console.log(todayTotalGRN);
    if (todayTotalGRN?.length > 0) {
      setTodayGrn(todayTotalGRN[0]?.mrpTotal);
    }
    // console.log(todayTotalDamage);
    if (todayTotalDamage?.length > 0) {
      setTodayDamage(todayTotalDamage[0]?.mrpTotal);
    }
  }, [
    isSuccess,
    data,
    isSuccessTodayGRN,
    todayTotalGRN,
    todayTotalDamage,
    isSuccessTodayDamage,
  ]);

  useEffect(() => {
    let g = 0;
    if (weeklyGRN?.length > 0) {
      weeklyGRN?.slice().map((grn) => {
        g = g + grn.mrpTotal;
      });
      setWeekGRN(g);
    }
    // console.log(dates)
  }, [weeklyGRN, isSuccessGRN]);

  useEffect(() => {
    let sales = 0;
    if (weeklySale?.length > 0) {
      weeklySale?.slice().map((sale) => {
        sales = sales + sale.grossTotalRound;
      });
      setWeekSales(sales);
    }
  }, [weeklySale, isSuccessSale]);

  // console.log(weekSales);
  // console.log(weekGRN);

  const handleSelectDay = (day) => {
    const today = new Date();
    const last3Day = subDays(today, 3);
    const last7Day = subDays(today, 7);
    const last30Day = subDays(today, 30);

    setEndDate(format(new Date(), "MM-dd-yyyy"));
    if (day === 3) {
      setStartDate(format(new Date(last3Day), "MM-dd-yyyy"));
    } else if (day === 7) {
      setStartDate(format(new Date(last7Day), "MM-dd-yyyy"));
    } else if (day === 30) {
      setStartDate(format(new Date(last30Day), "MM-dd-yyyy"));
    } else {
      setStartDate(format(new Date(), "MM-dd-yyyy"));
    }
    refetch();
  };

  useEffect(() => {
    refetch();
  }, [startDate, endDate, warehouse]);

  useEffect(() => {
    if (auth?.type === "admin") {
      setWarehouse("allWh");
    } else {
      setWarehouse(auth?.warehouse);
    }
  }, []);

  const handleOnchangeWareHouse = (e) => {
    if (e.option !== "no-warehouse") {
      setWarehouse(e.option);
    } else {
      setWarehouse("allWh");
    }
    console.log("handle data:", e);

    // console.log('')
    // refetch();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <SideBar></SideBar>
          </div>
          <div className="col-md-10">
            <Header title="Dashboard"></Header>
            <div className="row pt-3">
              <div className="col-md-6 d-flex ">
                {/* Start Date */}
                <div className="me-3 ">
                  <b>Start Date:</b>
                  <DatePicker
                    selected={new Date(startDate)}
                    className="form-control"
                    onChange={(date) =>
                      setStartDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                </div>

                {/* End Date */}
                <div className="me-3 ">
                  <b>End Date:</b>
                  <DatePicker
                    selected={new Date(endDate)}
                    className="form-control"
                    onChange={(date) =>
                      setEndDate(format(new Date(date), "MM-dd-yyyy"))
                    }
                  />
                </div>

                {/* Warehouse (Visible for Admins) */}
                {auth?.type === "admin" && (
                  <div className="me-3 col-md-3" style={{ width: "30%" }}>
                    <b>Warehouse:</b>
                    <WareHouseDW
                      id="warehouse"
                      name="warehouse"
                      handleOnChange={handleOnchangeWareHouse}
                      className="form-control"
                    />
                  </div>
                )}
              </div>

              {/* Date Range Buttons - Original Layout */}
              <div className="col-md-6 d-flex justify-content-end align-items-start ">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn btn-dark mx-1"
                    onClick={() => handleSelectDay(0)}
                  >
                    Today
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark mx-1"
                    onClick={() => handleSelectDay(3)}
                  >
                    Last 3 Days
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark mx-1"
                    onClick={() => handleSelectDay(7)}
                  >
                    Last Week
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark mx-1"
                    onClick={() => handleSelectDay(30)}
                  >
                    Last 30 Days
                  </button>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <Toast>
                  <Toast.Body>
                    <Icons.ShoppingBag> </Icons.ShoppingBag>
                    <strong className="ms-2 me-auto"> Bucket Size</strong>
                    <h2 className="text-left ps-4">
                      {Intl.NumberFormat().format(
                        (data?.mrpTotal
                          ? data?.mrpTotal / data?.footfall
                          : 0
                        )?.toFixed(2)
                      )}{" "}
                      ৳{/* <small> ৳</small> */}
                    </h2>
                  </Toast.Body>
                </Toast>
              </div>
              <div className="col-3">
                <Toast>
                  <Toast.Body>
                    <Icons.UserGroup> </Icons.UserGroup>
                    <strong className="ms-2 me-auto"> Foot Falls</strong>{" "}
                    <h2 className="text-left ps-4">
                      {data && Intl.NumberFormat().format(data?.footfall || 0)}
                      <small> People</small>
                    </h2>
                  </Toast.Body>
                </Toast>
              </div>
              <div className="col-3">
                <Toast>
                  <Toast.Body>
                    <Icons.ArchiveOutline className="ms-3" size={18} />
                    <strong className="ms-2 me-auto"> Sold Item</strong>
                    <h2 className="text-left ms-4">
                      {data? data?.totalItem : 0 } <small> items</small>
                    </h2>
                  </Toast.Body>
                </Toast>
              </div>
              <div className="col-3">
                <Toast>
                  <Toast.Body>
                    <Icons.ReplyOutline />
                    <strong className="ms-2 me-auto">Return Items</strong>
                    <h2 className="text-left ps-4">
                      {data?.returnItemTotal ? data?.returnItemTotal : 0}
                      <small> items</small>
                    </h2>
                  </Toast.Body>
                </Toast>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row pt-3 mb-3">
                  <div className="col-md-12">
                    <Card
                      className="bg-white text-dark"
                      style={{ boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 15px` }}
                    >
                      <LineChart className="h-20" startDate={startDate} endDate={endDate} warehouse={warehouse}/>
                    </Card>
                  </div>
                </div>
              </div>
              {/* <PieChart style={{ marginTop: `23px` }} /> */}
              {/* <div className="col-md-4 pt-3">
              <Card
                  className="bg-white text-dark mb-3"
                  style={{ boxShadow: `rgba(0, 0, 0, 0.25) 0px 5px 15px` }}
                >
                  <PieChart
                    sale={data?.mrpTotal}
                    cogs={data?.tpTotal}
                    lossProfit={data?.mrpTotal - data?.tpTotal}
                    style={{ marginTop: `20px` }}
                  />
                  <div className="row mt-4">
                    <div className="col-md-4">
                      <h2 className="text-center" style={{ fontSize: `16px` }}>
                        <small>Sale</small>
                        <br />
                        <b style={{ fontSize: `18px` }}>
                          {" "}
                          {Intl.NumberFormat().format(
                            data ? data?.mrpTotal?.toFixed(2) : 0
                          )}
                        </b>
                      </h2>
                    </div>
                    <div className="col-md-4">
                      <h2 className="text-center" style={{ fontSize: `16px` }}>
                        <small>COGS</small>
                        <br />
                        <b style={{ fontSize: `18px` }}>
                          {Intl.NumberFormat().format(
                            data?.tpTotal?.toFixed(2)
                          )}
                        </b>
                      </h2>
                    </div>
                    <div className="col-md-4">
                      <h2 className="text-center" style={{ fontSize: `16px` }}>
                        <small>Profit</small>
                        <br />
                        <b style={{ fontSize: `18px` }}>
                          {data
                            ? Intl.NumberFormat().format(
                                (data?.mrpTotal - data?.tpTotal).toFixed(2)
                              )
                            : 0}
                        </b>
                      </h2>
                    </div>
                  </div>
                </Card>
              </div> */}
            </div>
            <div className="row pt-3">
              <div className="col-md-4">
                <Toast className="m-0 w-full" style={{ width: "100%" }}>
                  <Toast.Body>
                    <Icons.CashOutline> </Icons.CashOutline>
                    <strong className="ms-2 me-auto"> Today's Sales</strong>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            MRP Total:{" "}
                          </span>
                        </p>
                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">Vat: </span>
                        </p>
                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Total Return:{" "}
                          </span>
                        </p>
                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Discount:{" "}
                          </span>
                        </p>
                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Promo Discount:{" "}
                          </span>
                        </p>
                        <p className="h4 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Gross Total:{" "}
                          </span>
                        </p>
                        <p className="h4 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Net Sales:{" "}
                          </span>
                        </p>

                        {/* <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            COGS Total:{" "}
                          </span>
                        </p>

                        <p className="h6 fw-normal pb-1">
                          <span className="ms-2 me-auto text-right">
                            Profit Total:{" "}
                          </span>
                        </p> */}
                      </div>
                      <div>
                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {data?.mrpTotal
                              ? Intl.NumberFormat().format(
                                  Math.round(data?.mrpTotal || 0)?.toFixed(2)
                                )
                              : 0}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {data?.vat
                              ? Intl.NumberFormat().format(
                                  Math.round(data?.vat)?.toFixed(2)
                                )
                              : 0}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {data?.returnTotal
                              ? Intl.NumberFormat().format(
                                  Math.round(data?.returnTotal)?.toFixed(2)
                                )
                              : 0}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {data?.discount
                              ? Intl.NumberFormat().format(
                                  Math.round(data?.discount)
                                )
                              : 0}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {data?.promo_discount
                              ? Intl.NumberFormat().format(
                                  Math.round(data?.promo_discount)
                                )
                              : 0}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        <p className="h4 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {data &&
                              Intl.NumberFormat().format(
                                data?.roundedGrossTotal || 0
                              )}
                            {/* {data?.grossTotalRound
                              ? Intl.NumberFormat().format(
                                  Math.round(data?.grossTotalRound).toFixed(2)
                                )
                              : 0} */}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>
                        <p className="h4 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {parseFloat(data?.roundedGrossTotal || 0)  -
                              parseFloat(data?.vat || 0)}
                            {/* {
                            data?.netSale
                              ? Intl.NumberFormat().format(
                                  data?.netSale?.toFixed(2)
                                )
                              : 0} */}
                            {/* <small> ৳</small> */}
                          </strong>
                        </p>

                        {/* <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {data?.cogs
                              ? Intl.NumberFormat().format(
                                  Math.round(data?.cogs)?.toFixed(2)
                                )
                              : 0}
                          </strong>
                        </p>

                        <p className="h6 border-bottom w-full pb-1">
                          <strong className="text-end">
                            {data
                              ? Intl.NumberFormat().format(data?.lossProfit)
                              : 0}
                          </strong>
                        </p> */}
                      </div>
                    </div>
                    {/* <h5 className=" text-right">
                    </h5> */}
                  </Toast.Body>
                </Toast>
              </div>
              <div className="col-md-3">
                <Toast className="m-0 w-full" style={{ width: "100%" }}>
                  <Toast.Body>
                    <Icons.CashOutline> </Icons.CashOutline>
                    <strong className="ms-2 me-auto"> Payments Recieved</strong>
                    <hr />
                    <p className="mb-1">
                      <strong>
                        Total Recieved:
                        <span className="text-lg text-end">
                          {data
                            ? Intl.NumberFormat().format(data?.totalReceived)
                            : 0}
                          ৳
                        </span>
                      </strong>
                    </p>
                    <div className="d-flex justify-content-between align-items-top">
                      <div>
                        <p className="mb-1">
                          <strong>Cash</strong>
                        </p>
                        <p className="mb-2 h5">
                          {data && Intl.NumberFormat().format(data?.cash || 0)}৳
                        </p>
                        <p className="mb-1">
                          <strong>Card</strong>
                        </p>
                        <small>
                          Visa: <b className="text-end">{data?.visa || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          Master Card:{" "}
                          <b className="text-end">{data?.masterCard || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          CITY: <b className="text-end">{data?.CITY || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          AMEX: <b className="text-end">{data?.AMEX || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          {" "}
                          DBBL: <b className="text-end">{data?.DBBL || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          BRAC: <b className="text-end">{data?.BRAC || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          EBL: <b className="text-end">{data?.EBL || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          MTB: <b className="text-end">{data?.MTB || 0} ৳</b>
                        </small>
                        {/* {data ? (
                          Object.entries(data).map(
                            ([key, value]) =>
                              value > 0 && (
                                <p key={key} className="fw-normal mb-0">
                                  {key}:{" "}
                                  <strong className="text-end tw-bold">
                                    {Intl.NumberFormat().format(value)}৳
                                  </strong>
                                </p>
                              )
                          )
                        ) : (
                          <></>
                        )} */}
                      </div>
                      <div>
                        <p className="mb-1">
                          <strong>Point Used</strong>
                        </p>
                        <p className="mb-2 h5">
                          {data?.paidAmount
                            ? Intl.NumberFormat().format(
                                data?.paidAmount?.point
                              )
                            : 0}
                          ৳
                        </p>

                        <p className="mb-1">
                          <strong>MFS</strong>
                        </p>
                        <small>
                          Bkash: <b className="text-end">{data?.bkash || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          Nagad: <b className="text-end">{data?.nagad || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          Upay: <b className="text-end">{data?.upay || 0} ৳</b>
                        </small>
                        <br />
                        <small>
                          Rocket: <b className="text-end">{data?.rocket || 0} ৳</b>
                        </small>
                        <br />
                      </div>
                    </div>
                    {/* <h5 className=" text-right">
                    </h5> */}
                  </Toast.Body>
                </Toast>
              </div>
              <div className="col-md-5">
                <div className="row gy-3">
                  <div className="col-6">
                    <Toast>
                      <Toast.Body>
                        <Icons.ShoppingBag> </Icons.ShoppingBag>
                        <strong className="ms-2 me-auto"> Bucket Size</strong>
                        <h2 className="text-left ps-4">
                          {Intl.NumberFormat().format(
                            (data?.mrpTotal
                              ? data?.mrpTotal / data?.footfall
                              : 0
                            )?.toFixed(2)
                          )}{" "}
                          ৳
                        </h2>
                      </Toast.Body>
                    </Toast>
                  </div>
                  <div className="col-6">
                    <Toast>
                      <Toast.Body>
                        <Icons.UserGroup> </Icons.UserGroup>
                        <strong className="ms-2 me-auto">
                          {" "}
                          Foot Falls
                        </strong>{" "}
                        <h2 className="text-left ps-4">
                          {data && Intl.NumberFormat().format(data?.footfall || 0)}
                          <small> People</small>
                        </h2>
                      </Toast.Body>
                    </Toast>
                  </div>
                  <div className="col-6">
                    <Toast>
                      <Toast.Body>
                        <Icons.ReplyOutline className="ms-3" size={18} />
                        <strong className="ms-2 me-auto"> Vat</strong>
                        <h2 className="text-left ps-4">
                          {data &&
                            Intl.NumberFormat().format(
                              data?.vat?.toFixed(2) || 0
                            )}{" "}
                          <small> ৳</small>
                        </h2>
                      </Toast.Body>
                    </Toast>
                  </div>
                  <div className="col-6">
                    <Toast>
                      <Toast.Body>
                        <Icons.CashOutline> </Icons.CashOutline>
                        <strong className="ms-2 me-auto">
                          Dues Collection
                        </strong>
                        <h2 className="text-left ps-4">
                          {data?.point
                            ? Intl.NumberFormat().format(data?.dueAmount)
                            : 0}
                          <small> ৳</small>
                        </h2>
                      </Toast.Body>
                    </Toast>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
