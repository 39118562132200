import React, { Component, Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useBillerDwQuery, useUserDwQuery } from "../../../services/userApi";
import { signInUser } from "../../Utility/Auth";

const BillerSelect = ({ handleOnchange, billerSelect }) => {
  let [customer, setCustomer] = useState([]);
  const auth = signInUser();
  const warehouse = auth?.warehouse
  const { data, error, isLoading, isFetching, isSuccess } = useBillerDwQuery(warehouse);

  // console.log("BillerData", data, billerSelect);

  useEffect(() => {
    let pos = [{ option: "no-user", label: "Select User" }];
    if (isSuccess) {
      if (data.length > 0) {
        data.map((user) => {
          pos = [
            ...pos,
            {
              option: user._id,
              label: `${user.name}`,
            },
          ];
        });
        setCustomer(pos);
      }
    }
  }, [data]);

  const handleOnchange1 = (value) => {
    console.log(value);
  };

  // console.log(data);
  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isDisabled={false}
        isLoading={false}
        isClearable={true}
        defaultValue={customer[0]}
        value={
          billerSelect &&
          customer[customer.map((obj) => obj.option).indexOf(billerSelect)]
        }
        onChange={handleOnchange}
        styles={{
          menu: (css) => ({ ...css, width: "200px" }),
          control: (css) => ({
            ...css,
            display: "inline-flex",
            width: "200px",
          }),
        }}
        isSearchable={true}
        name="Biller"
        options={customer}
      />
    </Fragment>
  );
};

export default BillerSelect;
