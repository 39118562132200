import { createSlice } from "@reduxjs/toolkit";
import { signInUser } from "../Components/Utility/Auth";

const user = signInUser();

const initialState = {
  billerId: "",
  userId: user?.id,
  warehouse: user?.warehouse || '',
  // regular sales
  salesCash: 0,
  salesVisa: 0,
  salesMasterCard: 0,
  salesBrac: 0,
  salesDbbl: 0,
  salesAmex: 0,
  salesCity: 0,
  salesMtbl: 0,
  salesEbl: 0,
  // salesUcb: 0,
  salesBkash: 0,
  salesRocket: 0,
  salesNagad: 0,
  salesUpay: 0,
  salesPoint: 0,
  salesTotalRound: 0,
  //Sales in Credits
  dueCash: 0,
  dueVisa: 0,
  dueMasterCard: 0,
  dueBrac: 0,
  dueDbbl: 0,
  dueAmex: 0,
  dueCity: 0,
  dueMtbl: 0,
  dueEbl: 0,
  // dueUcb: 0,
  dueBkash: 0,
  dueRocket: 0,
  dueNagad: 0,
  dueUpay: 0,
  dueTotalRound: 0,
  // Total Receieable
  totalCash: 0,
  totalVisa: 0,
  totalMasterCard: 0,
  totalBrac: 0,
  totalDbbl: 0,
  totalAmex: 0,
  totalCity: 0,
  totalMtbl: 0,
  totalEbl: 0,
  // totalUcb: 0,
  totalBkash: 0,
  totalRocket: 0,
  totalNagad: 0,
  totalUpay: 0,
  totalRound: 0,
  // collections
  collectionCash: 0, //-> available cash
  collectionVisa: 0,
  collectionMasterCard: 0,
  collectionBrac: 0,
  collectionDbbl: 0,
  collectionAmex: 0,
  collectionCity: 0,
  collectionMtbl: 0,
  collectionEbl: 0,
  // collectionUcb: 0,
  collectionBkash: 0,
  collectionRocket: 0,
  collectionNagad: 0,
  collectionUpay: 0,
  collectionTotalRound: 0,
  // Cash denomination
  oneThousand: 0,
  fiveHundred: 0,
  twoHundred: 0,
  oneHundred: 0,
  fifty: 0,
  twenty: 0,
  ten: 0,
  five: 0,
  two: 0,
  one: 0,
  oneThousandTotal: 0,
  fiveHundredTotal: 0,
  twoHundredTotal: 0,
  oneHundredTotal: 0,
  fiftyTotal: 0,
  twentyTotal: 0,
  tenTotal: 0,
  fiveTotal: 0,
  twoTotal: 0,
  oneTotal: 0,

  crediteSaleTotal: 0,
  cashOut: 0,
  officeBill: 0,
  openingCash: 0,
  difference: 0, //-> totalRecievedSum - totalCollectionSum

  cashInHand: 0, //-> All physical collected cash
  totalReceivedCash: 0, //-> cashInHand + cashout - opening Cash - officeBill
  totalReceievedSum: 0,
  totalCollectionSum: 0,

  status: "complete",
};
export const posCollectionSlice = createSlice({
  name: "posCollection",
  initialState: initialState,
  reducers: {
    selectBiller: (state, action) => {
      return {
        ...state,
        billerId: action.payload,
      };
    },
    selectPosData: (state, action) => {
      return {
        ...state,
        salesCash: action.payload.salesCash,
        salesVisa: action.payload.salesVisa,
        salesMasterCard: action.payload.salesMasterCard,
        salesBrac: action.payload.salesBrac,
        salesDbbl: action.payload.salesDbbl,
        salesAmex: action.payload.salesAmex,
        salesCity: action.payload.salesCity,
        salesMtbl: action.payload.salesMtbl,
        salesEbl: action.payload.salesEbl,
        // salesUcb: action.payload.salesUcb,
        salesBkash: action.payload.salesBkash,
        salesRocket: action.payload.salesRocket,
        salesNagad: action.payload.salesNagad,
        salesUpay: action.payload.salesUpay,
        salesPoint: action.payload.salesPoint,
        salesTotalRound: action.payload.salesTotalRound,
        dueCash: action.payload.dueCash,
        dueVisa: action.payload.dueVisa,
        dueMasterCard: action.payload.dueMasterCard,
        dueBrac: action.payload.dueBrac,
        dueDbbl: action.payload.dueDbbl,
        dueAmex: action.payload.dueAmex,
        dueCity: action.payload.dueCity,
        dueMtbl: action.payload.dueMtbl,
        dueEbl: action.payload.dueEbl,
        // dueUcb: action.payload.dueUcb,
        dueBkash: action.payload.dueBkash,
        dueRocket: action.payload.dueRocket,
        dueNagad: action.payload.dueNagad,
        dueUpay: action.payload.dueUpay,
        dueTotalRound: action.payload.dueTotalRound,
        totalCash: Number(action.payload.totalCash || 0) + Number(action.payload.dueCash || 0),
        totalVisa: Number(action.payload.totalVisa || 0) + Number(action.payload.dueVisa || 0),
        totalMasterCard:
          action.payload.salesMasterCard + action.payload.dueMasterCard,
        totalBrac: action.payload.salesBrac + action.payload.dueBrac,
        totalDbbl: action.payload.salesDbbl + action.payload.dueDbbl,
        totalAmex: action.payload.salesAmex + action.payload.dueAmex,
        totalCity: action.payload.salesCity + action.payload.dueCity,
        totalMtbl: action.payload.salesMtbl + action.payload.dueMtbl,
        totalEbl: action.payload.salesEbl + action.payload.dueEbl,
        // totalUcb: action.payload.salesUcb + action.payload.dueUcb,
        totalBkash: action.payload.salesBkash + action.payload.dueBkash,
        totalRocket: action.payload.salesRocket + action.payload.dueRocket,
        totalNagad: action.payload.salesNagad + action.payload.dueNagad,
        totalUpay: action.payload.salesUpay + action.payload.dueUpay,
        totalRound:
          action.payload.salesTotalRound + action.payload.dueTotalRound,
      };
    },
    selectWareHouse: (state, action) => {
      return { ...state, warehouse: action.payload };
    },
    selectVisa: (state, action) => {
      return {
        ...state,
        collectionVisa: action.payload,
      };
    },
    selectMasterCard: (state, action) => {
      return {
        ...state,
        collectionMasterCard: action.payload,
      };
    },
    selectBrac: (state, action) => {
      return {
        ...state,
        collectionBrac: action.payload,
      };
    },
    selectDbbl: (state, action) => {
      return {
        ...state,
        collectionDbbl: action.payload,
      };
    },
    selectAmex: (state, action) => {
      return {
        ...state,
        collectionAmex: action.payload,
      };
    },
    selectCity: (state, action) => {
      return {
        ...state,
        collectionCity: action.payload,
      };
    },
    selectMtbl: (state, action) => {
      return {
        ...state,
        collectionMtbl: action.payload,
      };
    },
    selectEbl: (state, action) => {
      return {
        ...state,
        collectionEbl: action.payload,
      };
    },
    // selectUcb: (state, action) => {
    //   return {
    //     ...state,
    //     collectionUcb: action.payload,
    //   };
    // },
    selectBkash: (state, action) => {
      return {
        ...state,
        collectionBkash: action.payload,
      };
    },
    selectRocket: (state, action) => {
      return {
        ...state,
        collectionRocket: action.payload,
      };
    },
    selectNagad: (state, action) => {
      return {
        ...state,
        collectionNagad: action.payload,
      };
    },
    selectUpay: (state, action) => {
      return {
        ...state,
        collectionUpay: action.payload,
      };
    },
    selectOneThousand: (state, action) => {
      const oneThousand = action.payload;
      return {
        ...state,
        oneThousand: action.payload,
        oneThousandTotal: parseInt(oneThousand) * 1000,
      };
    },
    selectFiveHundred: (state, action) => {
      const fiveHundred = action.payload;
      return {
        ...state,
        fiveHundred: action.payload,
        fiveHundredTotal: parseInt(fiveHundred) * 500,
      };
    },
    selectTwoHundred: (state, action) => {
      const twoHundred = action.payload;
      return {
        ...state,
        twoHundred: action.payload,
        twoHundredTotal: parseInt(twoHundred) * 200,
      };
    },
    selectOneHundred: (state, action) => {
      const oneHundred = action.payload;
      return {
        ...state,
        oneHundred: action.payload,
        oneHundredTotal: parseInt(oneHundred) * 100,
      };
    },
    selectFifty: (state, action) => {
      const fifty = action.payload;
      return {
        ...state,
        fifty: action.payload,
        fiftyTotal: parseInt(fifty) * 50,
      };
    },
    selectTwenty: (state, action) => {
      const twenty = action.payload;
      return {
        ...state,
        twenty: action.payload,
        twentyTotal: parseInt(twenty) * 20,
      };
    },
    selectTen: (state, action) => {
      const Ten = action.payload;
      return {
        ...state,
        ten: action.payload,
        tenTotal: parseInt(Ten) * 10,
      };
    },
    selectFive: (state, action) => {
      const Five = action.payload;
      return {
        ...state,
        five: action.payload,
        fiveTotal: parseInt(Five) * 5,
      };
    },
    selectTwo: (state, action) => {
      const Two = action.payload;
      return {
        ...state,
        two: action.payload,
        twoTotal: parseInt(Two) * 2,
      };
    },
    selectOne: (state, action) => {
      const One = action.payload;
      return {
        ...state,
        one: One,
        oneTotal: parseInt(One) * 1,
      };
    },
    selectCollectionCash: (state, action) => {
      return {
        ...state,
        collectionCash: action.payload,
      };
    },
    selectCollectionTotal: (state, action) => {
      return {
        ...state,
        collectionTotalRound: action.payload,
      };
    },
    selectCashOut: (state, action) => {
      return { ...state, cashOut: action.payload };
    },
    selectOfficeBill: (state, action) => {
      return { ...state, officeBill: action.payload };
    },
    selectOpeningCash: (state, action) => {
      return { ...state, openingCash: action.payload };
    },
    // cashInHand
    selectCashInHand: (state, action) => {
      return { ...state, cashInHand: action.payload };
    },
    // totalReceivedCash
    selectReceivedCash: (state, action) => {
      return { ...state, totalReceivedCash: action.payload };
    },
    // totalRecievedSum
    selectReceivedSum: (state, action) => {
      return { ...state, totalRecievedSum: action.payload };
    },
    // totalCollectionSum
    selectCollectionSum: (state, action) => {
      return { ...state, totalCollectionSum: action.payload };
    },
    selectDifference: (state, action) => {
      return { ...state, difference: action.payload };
    },
    resetDayBook: () => initialState,
  },
});

export const {
  selectBiller,
  selectPosData,
  resetPayment,
  selectVisa,
  selectMasterCard,
  selectBrac,
  selectDbbl,
  selectAmex,
  selectCity,
  selectMtbl,
  selectEbl,
  // selectUcb,
  selectBkash,
  selectRocket,
  selectNagad,
  selectUpay,
  selectOneThousand,
  selectFiveHundred,
  selectTwoHundred,
  selectOneHundred,
  selectFifty,
  selectTwenty,
  selectTen,
  selectFive,
  selectTwo,
  selectOne,
  selectCollectionCash,
  selectCollectionTotal,
  selectCashOut,
  selectOfficeBill,
  selectOpeningCash,

  selectCashInHand,
  selectReceivedCash,
  selectReceivedSum,
  selectCollectionSum,
  selectDifference,
  resetDayBook,
} = posCollectionSlice.actions;
export const posCollectionReducer = posCollectionSlice.reducer;
