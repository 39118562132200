import React from "react";

import * as Icons from "heroicons-react";

function CreateCustomerForm({ onSubmit, useForm }) {
  const { register, handleSubmit, reset } = useForm();
  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h6 className="mb-2">
        <Icons.UserOutline size={20} /> Basic Info
      </h6>
      <div className="form-group row mb-2">
        <label className="col-sm-3 col-form-label">Name</label>
        <div className="col-sm-9">
          <input
            type="text"
            className="form-control"
            {...register("name", { required: true })}
            placeholder="Name"
          />
        </div>
      </div>

      <div className="form-group row mb-2">
        <label className="col-sm-3 col-form-label">Email</label>
        <div className="col-sm-9">
          <input
            {...register("email")}
            type="email"
            className="form-control"
            id="email"
            placeholder="email"
          />
        </div>
      </div>
      <div className="form-group row mb-2">
        <label className="col-sm-3 col-form-label">Phone</label>
        <div className="col-sm-9">
          <input
            {...register("phone")}
            type="phone"
            className="form-control"
            id="phone"
            placeholder="phone"
          />
        </div>
      </div>
      <h6 className="mb-2 mt-4">
        <Icons.LocationMarkerOutline size={20} /> Address
      </h6>
      <div className="row">
        <div className="form-group col-4  mb-3">
          <label htmlFor="MCId">House</label>
          <input
            {...register("holdingNo")}
            type="text"
            className="form-control"
            id="holdingNo"
            placeholder="House"
          />
        </div>
        <div className="form-group col-4  mb-3">
          <label htmlFor="MCId">Road</label>
          <input
            {...register("road")}
            type="text"
            className="form-control"
            id="road"
            placeholder="Road"
          />
        </div>
        <div className="form-group col-4  mb-3">
          <label htmlFor="MCId">Sector</label>
          <select {...register("sector")} className="form-control">
            <option value="1">01</option>
            <option value="2">02</option>
            <option value="3">03</option>
            <option value="4">04</option>
            <option value="5">05</option>
            <option value="6">06</option>
            <option selected value="7">
              07
            </option>
            <option value="8">08</option>
            <option value="9">09</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
          </select>
        </div>
        <div className="form-group col-4  mb-3">
          <label htmlFor="MCId">Town</label>
          <select {...register("town")} className="form-control">
            <option selected value="Uttara">
              Uttara
            </option>
          </select>
        </div>
        <div className="form-group col-4  mb-3">
          <label htmlFor="MCId">City</label>
          <select {...register("city")} className="form-control">
            <option selected value="Dhaka">
              Dhaka
            </option>
          </select>
        </div>
        <div className="form-group col-4  mb-3">
          <label htmlFor="MCId">Division</label>
          <select {...register("division")} className="form-control">
            <option selected value="Dhaka">
              Dhaka
            </option>
          </select>
        </div>
        <div className="form-group col-4  mb-3">
          <label htmlFor="MCId">Zip Code</label>
          <select {...register("zipCode")} className="form-control">
            <option selected value="1230">
              1230
            </option>
          </select>
        </div>
        <div className="form-group col-8  mb-3">
          <label htmlFor="MCId">Country</label>
          <select {...register("country")} className="form-control">
            <option selected value="BD">
              Bangladesh
            </option>
          </select>
        </div>
      </div>
      <h6 className="mb-2 mt-4">
        <Icons.BadgeCheckOutline size={20} /> Membership
      </h6>
      <div className="row">
        <div className="form-group col-4  mb-3">
          <label htmlFor="inputMC">Member Ship</label>

          <select
            {...register("membership")}
            className="form-select"
            id="status"
          >
            <option value="gold" selected>
              Gold
            </option>
            <option value="diamond">Diamond</option>
            <option value="premium">Premium</option>
          </select>
        </div>
        <div className="form-group col-4  mb-3">
          <label htmlFor="inputMC">Customer Type</label>
          <select {...register("type")} className="form-select" id="status">
            <option value="regular" selected>
              Regular
            </option>
            <option value="premium">Premium</option>
            <option value="vip">VIP</option>
          </select>
        </div>
        <div className="form-group col-4  mb-3">
          <label htmlFor="inputMC">status</label>
          <select
            {...register("status")}
            className="form-select"
            id="status"
            placeholder="status"
          >
            <option value="active">active</option>
            <option value="inactive">inactive</option>
          </select>
        </div>
      </div>

      {/* Group */}
      <h6 className="mb-2 mt-4">
        <Icons.BadgeCheckOutline size={20} /> Group
      </h6>
      <div className="row">
        <div className="form-group col-6 mb-3">
          <label htmlFor="inputMC">Name</label>

          <select
            {...register("group")}
            className="form-select"
            id="status"
          >
            <option value={''}>Select Group</option>
            <option value="butex">BUTEX</option>
          </select>
        </div>
        <div className="form-group col-6  mb-3">
          <label htmlFor="inputMC">Batch</label>
          <input
            type="text"
            className="form-control"
            {...register("batch")}
            placeholder="Batch"
          />
        </div>
      </div>
      <div className="row ">
        <button
          type="reset"
          onClick={() => reset()}
          className="btn btn-outline-dark col-4 col-md-4"
        >
          Reset
        </button>
        <button type="submit" className="btn btn-dark col-8 col-md-8">
          <Icons.Plus />
          Customer
        </button>
      </div>
    </form>
  );
}

export default CreateCustomerForm;
